import React, { FC } from 'react'

import GridLines from '@bit/archilton.react-components.graph-paper'
import PropTypes from 'prop-types'
import type { Theme } from '../../types'
import styled from '@emotion/styled'
import { useTheme } from 'emotion-theming'

const SquaredPaper = styled(GridLines)<{ theme: Theme }>`
  background-color: ${({ theme }) => theme.color.background};
`

export type GraphPaperProps = {
  className?: string
}

const GraphPaper: FC<GraphPaperProps> = ({ className, children }) => {
  const theme = useTheme<Theme>()

  return (
    <SquaredPaper gridColor={theme.graphPaper} className={className}>
      {children}
    </SquaredPaper>
  )
}

export default GraphPaper
