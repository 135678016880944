import styled from '@emotion/styled'
import type { Theme } from '../../types'

const SketchyDiv = styled.div<{ theme: Theme }>`
  box-shadow: 20px 38px 34px -26px rgba(0, 0, 0, 0.3);
  border-radius: 255px 15px 225px 15px/15px 225px 15px 255px;
  border: 2px solid #2a2a2a;
  background-color: ${({ theme }) => theme.color.background};
  padding: 0.75em 1.5em;
  h1,
  h2,
  h3 {
    margin: 0.1em 0;
  }
  p {
    margin-block-end: 0;
  }
`

export default SketchyDiv
