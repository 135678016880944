import React, { FC } from 'react'

import Debug from 'debug'
import { HTMLContent } from '../components/Content/Content'
import { IndexPageTemplate } from '../pageTemplates/IndexPageTemplate'
import Layout from '../Layouts/Layout'
import { graphql } from 'gatsby'

const debug = Debug('web:index-page:')

const IndexPage: FC<{ data: GatsbyTypes.IndexPageQuery }> = ({ data }) => {
  debug(data)
  const { frontmatter, fields, html } = data.markdownRemark || {}
  const [nav] = data.allMarkdownRemark.edges || []
  const { navItems = [] } = nav?.node?.frontmatter || {}
  return (
    <Layout noHeader>
      <IndexPageTemplate
        {...frontmatter}
        {...fields}
        html={html}
        navItems={navItems}
        // contentComponent is to allow the cms and the site to preview differently
        contentComponent={HTMLContent}
      />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPage {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      html
      frontmatter {
        name
        role
        navLinks {
          linkType
          label
          url
          linkType
        }
      }
      fields {
        services {
          serviceTitle
          servicesList {
            title
            content
            bgColor
            color
          }
        }
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "navbar" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            navItems {
              label
              linkType
              url
            }
          }
        }
      }
    }
  }
`
